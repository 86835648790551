import React from 'react';
import { conf } from 'outlinejs';
import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

import StandImg from '../media/images/supporto.jpg';

export class MattedPrintsStandWidget extends BaseSelectionWidgetTwoColumns {
  constructor(props) {
    super(props);
    this.state = {
      value: props.quantity,
      isChosen: false,
      errors: [],
      showErrors: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.doChange = this.doChange.bind(this);
  }

  get name() {
    return 'standType';
  }

  get label() {
    return this.i18n.gettext('matted prints stand');
  }

  get quantityLabel() {
    return this.i18n.gettext('number of stands');
  }

  renderQuantityLabel() {
    return (
      <div className="row">
        <div className="product__option__subtitle">{this.quantityLabel}</div>
      </div>
    );
  }

  async handleChange(event) {
    let val = event.target.value;
    await this.setState({ isChosen: true, value: +val });
    this.doChange();
  }

  doChange() {
    let errors = [];
    let val = this.state.value || 0;
    this.props.delegate.setAccessoryConfigurationItem(
      this.props.delegate.standConfiguration,
      'quantity',
      val,
      null,
      true
    );

    if (!val) {
      errors.push({
        message: this.i18n.gettext('Il numero di stands non puo essere vuoto'),
        code: null
      });
    } else if (val <= 0) {
      errors.push({
        message: this.i18n.gettext('Insert a valid stands number'),
        code: null
      });
    }
    this.setState({ errors: errors, showErrors: true });
  }

  renderQuantity() {
    return (
      <div className="row">
        <div className="col-xs-12 col-sm-1 col-md-2 no-padding-col">
          <input
            type="number"
            className="form-control number__sheets"
            value={this.state.value || ''}
            onChange={this.handleChange}
          />
        </div>
      </div>
    );
  }

  renderQuantityWidget() {
    let quantityWidget = null;
    if (this.props.delegate.standConfiguration.standType !== conf.settings.MATTED_PRINTS_STAND_NO) {
      quantityWidget = (
        <div>
          {this.renderQuantityLabel()}
          {this.renderQuantity()}
        </div>
      );
    }
    return quantityWidget;
  }

  handleClick(item) {
    this.clickedItem = item;
    if (this.state.errors.length > 0) {
      this.props.delegate.setAccessoryConfigurationItem(
        this.props.delegate.standConfiguration,
        'quantity',
        1,
        null,
        true
      );
      this.setState({ errors: [], showErrors: false, value: 1 });
    }
    if (this.shouldComponentShowConfirmModal()) {
      this.showConfirmModal();
    } else {
      this.propagateClick();
    }
  }

  propagateClick() {
    if (this.clickedItem) {
      this.setState({ isChosen: true });
      this.props.delegate.setAccessoryConfigurationItem(
        this.props.delegate.standConfiguration,
        this.name,
        this.clickedItem.id,
        null,
        true
      );
    }
  }

  renderContext() {
    return (
      <div className="product__formats" data-product-formats="">
        <div className="row">
          <div className="accessory_img col-md-12 no-padding-col">
            <img src={StandImg} />
          </div>
          {this.renderItems()}
        </div>
        {this.renderQuantityWidget()}
        {this.renderErrors()}
      </div>
    );
  }
}
