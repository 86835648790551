import { BaseSelectionWidgetTwoColumns } from '../../core/components/baseSelectionWidgetTwoColumns';

export class PaperLaminationTypesWidget extends BaseSelectionWidgetTwoColumns {
  get name() {
    return 'paperLaminationType';
  }

  get label() {
    return this.i18n.gettext('plastificazione carta');
  }

  get calculatePrice() {
    return false;
  }

  // propagateClick() {
  //   if (this.clickedItem) {
  //     this.setState({isChosen: true});
  //     this.props.delegate.setConfigurationItem(this.name, this.clickedItem.id, null, true);
  //   }
  // }
}
