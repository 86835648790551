import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';

import { GuiErrors, navigateTo } from '../core/utils/index';
import Logger from '../core/logger';
import { BaseConfiguratorController } from '../core/controllers';
import { mattedPrintsFunnelSteps } from '../core/utils/breadcrumb';
import { BaseLoadingView } from '../core/views';

import { MattedPrintsContentView } from './views';
import {
  MattedPrintsProductCollection,
  MattedPrintsPriceCollection,
  MattedPrintsEventTypesCollection,
  MattedPrintsPaperTypesCollection,
  MattedPrintsLaminationTypesCollection,
  MattedPrintsOrientationTypesCollection,
  MattedPrintsFormatTypesCollection,
  MattedPrintsFrameColorMaterialTypesCollection,
  MattedPrintsStandTypesCollection,
  MattedPrintsAccessoryConfigurationCollection
} from './managers';
import { MattedPrintsConfiguration, MattedPrintsAccessoryConfiguration } from './models';
import { ProfessionalPreOrdersCollection } from '../core/managers';
import { Project } from '../projects/models';
import { createProfessionalPreOrder, mergeQueryParams } from '../core/utils';
import { MattedPrintsProjectStorage } from '../core/storage';
import { LayoutServicesCodes } from '../services/utils';
import { cleanMattedPrintsConfigurator } from '../core/storage/cleaners';
import { getConfigurationOptionalPriceParams, getConfigurationRequiredPriceParams } from './utils';
import EventTracker, { productInterface } from '../core/eventTracker';

/*
 * Controller used to initialize a new matted prints configuration
 * */
export class MattedPrintsCreateController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async init() {
    this.startInitialRendering();

    if (runtime.isClient) {
      // clean local forage data
      await cleanMattedPrintsConfigurator();

      //create matted prints configuration
      let mattedPrintsConfiguration = await this.createMattedPrintsConfiguration();

      if (mattedPrintsConfiguration) {
        navigateTo(this.request, this.response, 'matted-prints:main', {}, true, {
          configurationId: mattedPrintsConfiguration.id
        });
      } else {
        GuiErrors.modalError(
          this.i18n.gettext('Non è stato possibile creare la configurazione.'),
          'Error detail: MattedPrintsCreateController init',
          null,
          null,
          this.redirectToHomepage.bind(this)
        );
      }
    }
  }

  async createMattedPrintsConfiguration() {
    let mattedPrintsInitialConfiguration = conf.settings.MATTED_PRINTS_INITIAL_CONFIGURATION;
    let mattedPrintsConfiguration = new MattedPrintsConfiguration();
    try {
      mattedPrintsConfiguration = await mattedPrintsConfiguration.save(
        mattedPrintsInitialConfiguration
      );
      Logger.info('Matted Prints Configuration id ', mattedPrintsConfiguration.id);
      return mattedPrintsConfiguration;
    } catch (err) {
      Logger.error('MattedPrintsCreateController.createMattedPrintsConfiguration', {
        error: err
      });
      return null;
    }
  }
}

/*
 * Controller used to restore a saved album configuration
 * */
export class MattedPrintsRestoreProjectController extends BaseConfiguratorController {
  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async getProject(projectId) {
    return await new Project({ id: projectId }).fetch();
  }

  async getPreOrders(projectId) {
    return await new ProfessionalPreOrdersCollection().filterByProjectId(projectId);
  }

  async redirectToMattedPrintsNew() {
    navigateTo(this.request, this.response, 'matted-prints:new', {}, true);
  }

  async init(projectId) {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      let mattedPrintsConfigurationId = null;
      let packagingConfigurationId = null;
      let mattedPrintsPreOrderId = null;
      let mattedPrintsPackagingPreOrderId = null;
      let project = null;
      let preOrders = null;

      let nextState = this.request.query['next-url']
        ? this.request.query['next-url']
        : 'matted-prints:main';

      try {
        [project, preOrders] = await Promise.all([
          this.getProject(projectId),
          this.getPreOrders(projectId)
        ]);
      } catch (err) {
        if (err.code === 404) {
          GuiErrors.modalInfo(
            this.i18n.gettext(
              'Il prodotto che stai cercando è stato eliminato. Puoi procedere configurando un nuovo prodotto.'
            ),
            null,
            null,
            null,
            this.redirectToMattedPrintsNew.bind(this)
          );
          return;
        } else {
          Logger.error(
            'MattedPrintsRestoreProjectController.init - failed to fetch project or preorder',
            {
              error: err,
              projectId: projectId,
              project: project,
              preOrders: preOrders
            }
          );
          nextState = conf.settings.HOMEPAGE_BASE_URL;
        }
      }

      if (project && preOrders) {
        try {
          preOrders.forEach((preOrder) => {
            if (preOrder.productType === conf.settings.MATTED_PRINTS_PRODUCT_TYPE) {
              mattedPrintsPreOrderId = preOrder.id;
              mattedPrintsConfigurationId = preOrder.configurationId;
            }
            if (preOrder.productType === conf.settings.MATTED_PRINTS_PACKAGING_PRODUCT_TYPE) {
              mattedPrintsPackagingPreOrderId = preOrder.id;
              packagingConfigurationId = preOrder.configurationId;
            }
          });

          // set new configurator data
          await Promise.all([MattedPrintsProjectStorage.setName(project.name)]);
        } catch (err) {
          Logger.error(
            'MattedPrintsRestoreProjectController.init - failed to get configuration id',
            {
              error: err,
              project: project,
              preOrders: preOrders
            }
          );
          nextState = conf.settings.HOMEPAGE_BASE_URL;
        }
      }

      navigateTo(this.request, this.response, nextState, {}, true, {
        configurationId: mattedPrintsConfigurationId,
        packagingConfigurationId: packagingConfigurationId,
        preOrderId: mattedPrintsPreOrderId,
        packagingPreOrderId: mattedPrintsPackagingPreOrderId,
        professionalProjectId: project.id
      });
    }
  }
}

export class MattedPrintsController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get productType() {
    return 'MattedPrints';
  }

  get funnelStep() {
    return 1;
  }

  get funnelSteps() {
    return mattedPrintsFunnelSteps(this.request);
  }

  getCallToAction() {
    return this.i18n.gettext('Procedi');
  }

  getTitle() {
    return this.product && this.product.productItem && this.product.productItem.familyName
      ? this.product.productItem.familyName
      : this.i18n.gettext('Matted Prints');
  }

  /* extract (if possible) the default value from an item collection
   * and set it into configuration
   */
  setDefaultValueByItems(items) {
    let defaultValue = null;
    let isValidOption = false;
    let selected = this.configuration[items.model.modelName];
    /* se nella collection c'è bypassDefault non impostare il default */
    if (items.length === 1 && !items.bypassDefault) {
      defaultValue = items.first().id;
    } else {
      items.each(function (item, $selected, $isValidOption, $defaultValue) {  //eslint-disable-line
        isValidOption = isValidOption || item.id === selected;
        if (item.isDefault) {
          defaultValue = item.id;
        }
      });
    }

    /* avoid update of disabled widgets */
    let disabledWidgets = this.getdisabledWidgets();
    let widgetDisabled = disabledWidgets.indexOf(items.model.modelName) > -1;
    if (widgetDisabled) {
      defaultValue = this.product.productConfiguration[items.model.modelName];
    }

    if (!isValidOption && defaultValue) {
      this.configuration[items.model.modelName] = defaultValue;
    }
  }

  get context() {
    return Object.assign(super.context, {
      product: this.product, // informazioni sul prodotto
      configuration: this.configuration, // stato dei filtri
      standConfiguration: this.standConfiguration, // stato dei filtri di stand
      accessoryExistingConfiguration: this.accessoryExistingConfiguration,
      filters: this.filters, // collection dei filtri
      filtersViewValidity: this.filtersViewValidity,
      priceValidity: this.priceValidity,
      errorsVisibility: this.errorsVisibility,
      user: this.user,
      customerUser: this.customerUser,
      loadingCollection: this.loadingCollection,
      initViewRendering: this.initViewRendering,
      loadingPage: this.loadingPage,
      loadingPreview: this.loadingPreview,
      productConfigurationIsLoading: this.productConfigurationIsLoading,
      filtersViewIsLoading: this.filtersViewIsLoading,
      updatePrice: this.updatePrice,
      unitOfMeasure: this.unitOfMeasure
    });
  }

  get view() {
    return MattedPrintsContentView;
  }

  async initContentProps() {
    super.initContentProps();

    // initialize content view properties
    this.product = {
      collection: new MattedPrintsProductCollection(),
      productItem: {},
      previewUrl: null,
      productConfiguration: new MattedPrintsConfiguration(),
      price: {},
      showWidget: false,
      showWidgetPrice: this.customerUserIsAuthorized
    };

    this.graphicsLoaded = false;
    this.accessoryExistingConfiguration = null;
  }

  get standAccessoryType() {
    return conf.settings.MATTED_PRINTS_STAND_ACCESSORY_TYPE;
  }

  async loadInitialConfiguration() {
    let configuration = {};
    let currentConfiguration;
    let configurationId = this.request.query.configurationId;
    let accessoryExistingConfiguration;

    if (configurationId) {
      Logger.info('Matted Prints ConfigurationId', configurationId);
      currentConfiguration = new MattedPrintsConfiguration({ id: configurationId });

      try {
        await currentConfiguration.fetch();
      } catch (err) {
        if (err.code === 404) {
          GuiErrors.modalInfo(
            this.i18n.gettext(
              'La configurazione che stai cercando è stata eliminata. Puoi procedere configurando un nuovo prodotto.'
            )
          );
          navigateTo(this.request, this.response, 'matted-prints:new', {}, true, null);
          return false;
        } else {
          Logger.error(
            'MattedPrintsController.loadInitialConfiguration - MattedPrintsConfiguration FETCH',
            {
              error: err,
              id: configurationId
            }
          );
          return configuration;
        }
      }

      this.product.productConfiguration = currentConfiguration;
      Logger.info('restored configuration', currentConfiguration.toJSON());

      configuration = {
        eventType: currentConfiguration.eventType,
        frameColorMaterialType: currentConfiguration.frameColorMaterialType,
        formatType: currentConfiguration.formatType,
        orientationType: currentConfiguration.orientationType,
        paperType: currentConfiguration.paperType,
        paperLaminationType: currentConfiguration.paperLaminationType,
        quantity: currentConfiguration.quantity,
        packagingConfigurationId: currentConfiguration.packagingConfigurationId
      };

      this.configuration = configuration;

      accessoryExistingConfiguration = await this.getAccessoryExistingConfiguration();

      this.standConfiguration = accessoryExistingConfiguration
        ? accessoryExistingConfiguration
        : {
            quantity: 1,
            standType: 'CONFIG_MATTED_PRINTS_STAND_NO',
            mattedPrintsProductType: 'MATTED_PRINTS',
            shopCode:
              this.customerUser && this.customerUser.shopCode ? this.customerUser.shopCode : null
          };
    } else {
      // if configurationId is not present redirect to albumepoca
      this.redirectToHomepage();
      return null;
    }
  }

  async getAccessoryExistingConfiguration() {
    let accessoryExistingConfigurationsCollection;
    let accessoryExistingConfiguration = null;
    let accessoryConfiguration = new MattedPrintsAccessoryConfigurationCollection();
    try {
      accessoryExistingConfigurationsCollection = await accessoryConfiguration.fetch({
        data: {
          mattedPrintsConfigurationId: this.product.productConfiguration.id,
          mattedPrintsProductType: this.product.productConfiguration.productType
        },
        processData: true
      });
    } catch (err) {
      Logger.error(
        'MattedPrintsController.getAccessoryExistingConfiguration - unable to fetch accessory existing accessory configurations',
        {
          error: err
        }
      );
    }

    if (accessoryExistingConfigurationsCollection.length > 0) {
      await accessoryExistingConfigurationsCollection.map((accessory) => {
        if (accessory.productType === conf.settings.MATTED_PRINTS_STAND_ACCESSORY_TYPE) {
          this.accessoryExistingConfiguration = accessory;
          accessoryExistingConfiguration = {
            id: accessory.id,
            quantity: accessory.quantity,
            standType: accessory.standType,
            mattedPrintsProductType: accessory.mattedPrintsProductType,
            shopCode: accessory.shopCode
          };
        }
      });
    }
    return accessoryExistingConfiguration;
  }

  async init() {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      let initialConfiguration = await this.loadInitialConfiguration();

      this.stopInitialRendering();

      if (initialConfiguration !== null) {
        await this.reloadView();
      }

      EventTracker.log(
        this.customerUser,
        'configurator_view',
        productInterface(this.product.productConfiguration)
      );
    }
  }

  async reloadView(getPrice) {
    this.productConfigurationIsLoading = true;
    this.filtersViewIsLoading = true;
    if (getPrice) {
      this.updatePrice = false;
    }
    this.render(this.context);
    try {
      this.resetFilterErrorsCollection();

      await Promise.all([
        this.loadCollection(new MattedPrintsEventTypesCollection()),
        this.loadCollection(new MattedPrintsOrientationTypesCollection())
      ]);

      await Promise.all([
        this.loadCollection(new MattedPrintsFormatTypesCollection()),
        this.loadCollection(new MattedPrintsFrameColorMaterialTypesCollection()),
        this.loadCollection(new MattedPrintsPaperTypesCollection())
      ]);

      await Promise.all([
        this.loadCollection(new MattedPrintsLaminationTypesCollection()),
        this.loadAccessoryCollection(
          new MattedPrintsStandTypesCollection(),
          this.standConfiguration
        )
      ]);
    } catch (err) {
      Logger.error('MattedPrintsController.reloadView-loadFilters', {
        error: err,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
    } finally {
      this.filtersViewIsLoading = false;
      this.render(this.context);
    }
    this.getPriceForCurrentConfiguration(getPrice);
  }

  async getPriceForCurrentConfiguration(getPrice = true) {
    if (this.filters === []) {
      let timestamp = new Date().getTime();
      Logger.error('MattedPrintsController.getPriceForCurrentConfiguration', {
        error: 'Empty filters',
        errorCode: timestamp,
        configuration: this.configuration,
        productConfiguration: this.product.productConfiguration,
        filters: this.filters
      });
      this.productConfigurationIsLoading = false;
      this.render(this.context);
    } else {
      try {
        this.loadProduct();
        if (getPrice) {
          this.loadPrice();
        }
      } catch (err) {
        Logger.error('MattedPrintsController.getPriceForCurrentConfiguration', {
          error: err,
          configuration: this.configuration,
          productConfiguration: this.product.productConfiguration,
          filters: this.filters
        });
      } finally {
        this.productConfigurationIsLoading = false;
        this.updatePrice = true;
        this.render(this.context);
      }
    }
  }

  getConfigurationRequiredParams() {
    return {
      frameColorMaterialType: this.configuration.frameColorMaterialType,
      orientationType: this.configuration.orientationType,
      formatType: this.configuration.formatType,
      paperType: this.configuration.paperType,
      quantity: this.configuration.quantity,
      standType: this.standConfiguration.standType,
      standQuantity: this.standConfiguration.quantity
    };
  }

  getConfigurationOptionalParams() {
    let params = {};

    if (this.filters.paperLaminationTypes && this.filters.paperLaminationTypes.length > 0) {
      params.paperLaminationType = this.configuration.paperLaminationType;
    }

    return params;
  }

  async loadProduct() {
    let requiredParams = this.getConfigurationRequiredParams();
    let productParams = Object.assign(requiredParams, this.getConfigurationOptionalParams());
    let product = await super.getProduct(new MattedPrintsProductCollection(), productParams);
    if (product) {
      this.setConfigurationProduct(product);
    }
  }

  async setConfigurationProduct(productItem) {
    super.setConfigurationProduct(productItem);
    this.render(this.context);
  }

  async loadPrice() {
    // let requiredParams = this.getConfigurationRequiredParams();
    // requiredParams = Object.assign(requiredParams, {
    //   shopCode: this.customerUserIsAuthorized ? this.customerUser.shopCode : null
    // });
    //
    // let priceParams = Object.assign(requiredParams, this.getConfigurationOptionalParams());
    // let price = await this.getPrice(new MattedPrintsPriceCollection(), priceParams);
    // if (price) {
    //   this.setConfigurationPrice(price);
    // }
    let requiredParams = await getConfigurationRequiredPriceParams(
      this.configuration,
      this.standConfiguration
    );
    requiredParams = Object.assign(requiredParams, {
      shopCode: this.customerUserIsAuthorized ? this.customerUser.shopCode : null
    });

    let optionalParams = await getConfigurationOptionalPriceParams(
      this.filters,
      this.configuration
    );

    let priceParams = Object.assign(requiredParams, optionalParams);
    let price = await this.getPrice(new MattedPrintsPriceCollection(), priceParams);
    if (price) {
      this.setConfigurationPrice(price);
    }
  }

  getPreparedConfiguration(configuration) {
    let shopcode = null;
    if (this.customerUserIsAuthorized) {
      shopcode = this.customerUser.shopCode;
    }

    if (!configuration) {
      configuration = this.configuration;
    }
    configuration.shopCode = shopcode;
    configuration.quantity = this.configuration.quantity;

    configuration.eventType =
      this.filters.eventTypes && this.filters.eventTypes.length > 0
        ? configuration.eventType
        : null;
    configuration.paperLaminationType =
      this.filters.paperLaminationTypes && this.filters.paperLaminationTypes.length > 0
        ? configuration.paperLaminationType
        : null;

    return configuration;
  }

  getAccessoryPreparedConfiguration(accessoryConfiguration, configurationId) {
    let shopcode = null;
    if (this.customerUserIsAuthorized) {
      shopcode = this.customerUser.shopCode;
    }

    if (!accessoryConfiguration) {
      accessoryConfiguration = this.standConfiguration;
    }
    accessoryConfiguration.shopCode = shopcode;
    accessoryConfiguration.mattedPrintsConfigurationId = configurationId;

    accessoryConfiguration.standType =
      this.filters.standTypes && this.filters.standTypes.length > 0
        ? accessoryConfiguration.standType
        : null;

    return accessoryConfiguration;
  }

  async saveProductConfiguration() {
    try {
      let currentConfiguration = this.getPreparedConfiguration();
      let productConfiguration = this.product.productConfiguration;
      let mattedPrintsConfiguration = await productConfiguration.save(currentConfiguration);
      Logger.info('Matted Prints Configuration id: ', this.product.productConfiguration.id);
      return mattedPrintsConfiguration;
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error('MattedPrintsController.saveProductConfiguration', {
        error: err,
        errorCode: timestamp,
        configuration: this.product.productConfiguration
      });
      GuiErrors.modalError(
        this.i18n.gettext(
          'Non è stato possibile salvare la configurazione, si prega di ripetere la procedura.'
        ),
        'Error detail: saveProductConfiguration - code: ' + timestamp
      );
      return undefined;
    }
  }

  async createMattedPrintsAccessoryConfiguration(configurationId) {
    let mattedPrintsAccessoryConfiguration = new MattedPrintsAccessoryConfiguration();
    let accessoryCurrentConfiguration = this.getAccessoryPreparedConfiguration(
      this.standConfiguration,
      configurationId
    );
    try {
      mattedPrintsAccessoryConfiguration = await mattedPrintsAccessoryConfiguration.save(
        accessoryCurrentConfiguration
      );
      Logger.info('Matted Prints Stand Configuration id:', mattedPrintsAccessoryConfiguration.id);
      return mattedPrintsAccessoryConfiguration;
    } catch (err) {
      Logger.error('MattedPrintsCreateController.createMattedPrintsAccessoryConfiguration', {
        error: err
      });
      return null;
    }
  }

  async updateMattedPrintsAccessoryConfiguration() {
    try {
      await this.accessoryExistingConfiguration.save(this.standConfiguration);
    } catch (err) {
      Logger.error('MattedPrintsCreateController.updateMattedPrintsAccessoryConfiguration', {
        error: err
      });
      return null;
    }
  }

  async getAccessoryPreOrderList() {
    return await new ProfessionalPreOrdersCollection().filterByConfigurationIdAndProductType(
      this.standConfiguration.id,
      conf.settings.MATTED_PRINTS_STAND_ACCESSORY_TYPE
    );
  }

  async getProductPreOrderList() {
    return await new ProfessionalPreOrdersCollection().filterByConfigurationIdAndProductType(
      this.request.query.configurationId,
      conf.settings.MATTED_PRINTS_PRODUCT_TYPE
    );
  }

  async goToNextStep() {
    this.errorsVisibility = true;
    this.startLoadingPage();

    if (await this.checkWidgetErrors()) {
      let mattedPrintsConfiguration = await this.saveProductConfiguration();
      if (mattedPrintsConfiguration) {
        let createStandConfiguration = false;
        let updateStandConfiguration = false;
        let deleteStandConfiguration = false;
        let createStandPreOrder = false;
        let deleteStandPreOrder = false;

        let accessoryPreOrderList = [];
        let accessoryPreOrder;
        let professionalProjectId;

        let queryParams = mergeQueryParams(this.request.query, {
          configurationId: mattedPrintsConfiguration.id,
          packagingConfigurationId: mattedPrintsConfiguration.packagingConfigurationId
        });

        let standConfigurationEnabled =
          this.standConfiguration.standType !== conf.settings.MATTED_PRINTS_STAND_NO;
        if (standConfigurationEnabled) {
          if (this.accessoryExistingConfiguration) {
            updateStandConfiguration = true;
          } else {
            createStandConfiguration = true;
          }
        } else {
          if (this.accessoryExistingConfiguration) {
            deleteStandConfiguration = true;
          }
        }

        if (this.isUserLoggedIn()) {
          accessoryPreOrderList = await this.getAccessoryPreOrderList();
          if (accessoryPreOrderList.length > 0 && !standConfigurationEnabled) {
            accessoryPreOrder = accessoryPreOrderList.first();
            deleteStandPreOrder = true;
          }

          if (accessoryPreOrderList.length === 0 && standConfigurationEnabled) {
            // get projectId to create preOrder
            let productPreorderList = await this.getProductPreOrderList();
            let productPreorder = productPreorderList.first();

            // check querystring
            if (productPreorder && !this.request.query.professionalProjectId) {
              Logger.warning(
                'MattedPrintsCreateController - professionalProjectId not present in query params'
              );
            }

            if (productPreorder) {
              professionalProjectId = productPreorder.projectId;
              createStandPreOrder = true;
            }
          }
        }

        if (createStandConfiguration) {
          let accessoryConfiguration = await this.createMattedPrintsAccessoryConfiguration(
            mattedPrintsConfiguration.id
          );
          if (createStandPreOrder && professionalProjectId) {
            await createProfessionalPreOrder(
              this.customerUser,
              accessoryConfiguration,
              professionalProjectId,
              accessoryConfiguration.quantity
            );
          }
        }

        if (updateStandConfiguration) {
          await this.updateMattedPrintsAccessoryConfiguration();
        }

        if (deleteStandConfiguration) {
          await this.updateMattedPrintsAccessoryConfiguration();

          if (deleteStandPreOrder) {
            await accessoryPreOrder.destroy();
          }
        }

        navigateTo(
          this.request,
          this.response,
          'matted-prints-packaging:main',
          {},
          false,
          queryParams
        );
      }
    }
    this.stopLoadingPage();
  }

  getdisabledWidgets() {
    let disabledList = [];
    let code = this.product.productConfiguration.serviceConfigurationTypeCode;
    if (code === LayoutServicesCodes.aeVeloceCode) {
      disabledList = [
        'quantity',
        'orientationType',
        'formatType',
        'paperType',
        'paperLaminationType'
      ];
    }
    return disabledList;
  }
}
