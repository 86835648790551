import React from 'react';
import BaseBootstrapWidget from '../../core/components/baseBootstrapWidget';

export class FormatTypesWidget extends BaseBootstrapWidget {
  constructor(props) {
    super(props);
    this.state = {
      errors: []
    };
  }

  get name() {
    return 'formatType';
  }

  handleUnityClick(code, event) {
    event.preventDefault();
    this.props.delegate.updateUnityOfMeasure(code);
  }

  get hasConfirmModal() {
    return true;
  }

  shouldComponentShowConfirmModal() {
    //return this.props.delegate.hasCoverEditorWidget() && this.props.delegate.isCoverEditorProjectValid();
    return false;
  }

  async onModalConfirmClick() {
    //this.props.delegate.resetCoverEditorProject();
  }

  render() {
    let items = [];
    const unitOfMeasure = this.props.unitOfMeasure;

    if (this.props.items && this.props.items.map) {
      items = this.props.items.map((item) => {
        let activeClass = item.id === this.props.selected ? 'active' : '';
        let itemClass = `btn-ae text-center ${activeClass}`;
        let unityName = unitOfMeasure === 'cm' ? item.nameCm : item.nameIn;
        return (
          <div key={item.id} className="col-xs-6 col-sm-3 col-md-3 no-padding-col">
            <div
              className={itemClass}
              onClick={this.handleClick.bind(this, item)}
              data-action={`set_${this.props.items.name}_${item.id}`}>
              {item.name}
              {unityName}
            </div>
          </div>
        );
      });
    }

    return (
      <div className={this.visibleCLass()}>
        <div className="row">
          <div className="col-xs-2 col-sm-2 col-md-2 no-padding-col">
            <div className="product__option__subtitle">{this.i18n.gettext('Formato')}</div>
          </div>
          <div className="col-xs-2 col-sm-10 col-md-10 text-left padding-col-dx">
            <div className="product__option__subtitle">
              <a
                href="#"
                onClick={this.handleUnityClick.bind(this, 'cm')}
                className={unitOfMeasure === 'cm' ? 'active' : ''}>
                {this.i18n.gettext('cm')}
              </a>
              /
              <a
                href="#"
                className={unitOfMeasure === 'in' ? 'active' : ''}
                onClick={this.handleUnityClick.bind(this, 'in')}>
                {this.i18n.gettext('in')}
              </a>
            </div>
          </div>
        </div>
        <div className="product__formats" data-product-formats="" id={this.name}>
          <div className="row">
            <div className={this.loadingClass}>
              <div className="row">{items}</div>
            </div>
          </div>
        </div>
        {this.renderConfirmModal()}
      </div>
    );
  }
}
