import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import {
  MattedPrintsController,
  MattedPrintsCreateController,
  MattedPrintsRestoreProjectController
} from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('matted-prints:main', MattedPrintsController),
      'new/': routing.url('matted-prints:new', MattedPrintsCreateController),
      new: routing.url('matted-prints:new', MattedPrintsCreateController),
      'edit/:projectId:': routing.url(
        'matted-prints:restoreProject',
        MattedPrintsRestoreProjectController
      )
    };
  }
}
