import React from 'react';

import { BaseContentView, BaseProductView, BaseFiltersView } from '../core/views';

import { ProjectNameWidget } from '../core/components/projectNameWidget';
import { MattedPrintsMaterialPreview } from './components/mattedPrintsMaterialPreview';
import { FiltersTitle } from '../core/components/filtersTitle';

import { EventTypesWidget } from './components/eventTypesWidget';
import { OrientationTypesWidget } from './components/orientationTypesWidget';
import { FormatTypesWidget } from './components/formatTypesWidget';
import { FrameColorMaterialTypeWidget } from './components/frameColorMaterialTypesWidget';
import { QuantityNumberWidget } from './components/quantityNumberWidget';
import { MattedPrintsStandWidget } from './components/mattedPrintsStandWidget';
import { PaperTypesWidget } from './components/paperTypesWidget';
import { PaperLaminationTypesWidget } from './components/paperLaminationTypesWidget';

class ProductView extends BaseProductView {
  priceIsLoading() {
    let priceIsLoading;
    if (this.props.updatePrice) {
      priceIsLoading = false;
    } else {
      priceIsLoading =
        this.props.loadingCollection.mattedPrintsPrices || this.props.productConfigurationIsLoading;
    }
    return priceIsLoading;
  }

  renderPreview() {
    return (
      <div className="row product-preview album-product-preview">
        <div className="col-xs-12">
          <MattedPrintsMaterialPreview
            product={this.props.product}
            height={this.state.productPreviewHeight}
            width={this.state.productPreviewWidth}
          />
        </div>
      </div>
    );
  }
}

class FiltersView extends BaseFiltersView {
  getFilters() {
    return [
      <FiltersTitle
        key="FiltersTitle"
        title={this.i18n.gettext('Personalizza il tuo matted prints')}
      />,

      <ProjectNameWidget
        key="ProjectNameWidget"
        delegate={this.delegate}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
      />,

      <EventTypesWidget
        key="EventTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.eventTypes}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <QuantityNumberWidget
        key="SheetsNumberWidget"
        delegate={this.delegate}
        items={this.props.filters.quantity || { min: 5, max: 16 }}
        selected={this.props.configuration.quantity}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PaperTypesWidget
        key="PaperTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.paperTypes}
        selected={this.props.configuration.paperType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <PaperLaminationTypesWidget
        key="PaperLaminationTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.paperLaminationTypes}
        selected={this.props.configuration.paperLaminationType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <OrientationTypesWidget
        key="OrientationTypesWidget"
        delegate={this.delegate}
        items={this.props.filters.orientationTypes}
        selected={this.props.configuration.orientationType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <FormatTypesWidget
        key="FormatTypesWidget"
        delegate={this.delegate}
        unitOfMeasure={this.delegate.unitOfMeasure}
        items={this.props.filters.formatTypes}
        selected={this.props.configuration.formatType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <FrameColorMaterialTypeWidget
        key="FrameColorMaterialTypeWidget"
        delegate={this.delegate}
        items={this.props.filters.frameColorMaterialTypes}
        selected={this.props.configuration.frameColorMaterialType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        loadingCollection={this.props.loadingCollection}
      />,

      <MattedPrintsStandWidget
        key="MattedPrintsStandWidget"
        delegate={this.delegate}
        items={this.props.filters.standTypes}
        selected={this.props.standConfiguration.standType}
        pushWidgetError={this.pushWidgetError}
        popWidgetError={this.popWidgetError}
        quantity={this.props.standConfiguration.quantity}
        loadingCollection={this.props.loadingCollection}
      />
    ];
  }
}

export class MattedPrintsContentView extends BaseContentView {
  render() {
    return super.render(ProductView, FiltersView);
  }

  constructor(props) {
    super(props);
  }
}
