import { BaseConfiguratorProductStorage } from '../core/storage/index';
import { MattedPrintsConfiguration } from './models';
import { MattedPrintsAccessoryConfigurationCollection } from './managers';
import Logger from '../core/logger';
import { conf } from 'outlinejs';

export class MattedPrintsStorage extends BaseConfiguratorProductStorage {
  static get productPrefixKey() {
    return 'MattedPrints';
  }
}

export async function getMattedPrintsConfiguration(configurationId, params) {
  let configuration = null;
  if (configurationId) {
    configuration = new MattedPrintsConfiguration({ id: configurationId });
    if (params) {
      await configuration.fetch({ data: params });
    } else {
      await configuration.fetch();
    }
  }
  return configuration;
}

export async function getMattedPrintsAccessoryConfiguration(configurationId, productType) {
  let accessoryConfigurationCollection = null;
  let accessoryConfiguration = null;
  if (configurationId) {
    accessoryConfigurationCollection = new MattedPrintsAccessoryConfigurationCollection();
    try {
      await accessoryConfigurationCollection.fetch({
        data: {
          mattedPrintsConfigurationId: configurationId,
          mattedPrintsProductType: productType
        },
        processData: true
      });
    } catch (err) {
      Logger.error(
        'getMattedPrintsAccessoryConfiguration - unable to fetch accessory existing configurations',
        {
          error: err
        }
      );
    }

    if (accessoryConfigurationCollection.length > 0) {
      await accessoryConfigurationCollection.map((accessory) => {
        if (accessory.productType === conf.settings.MATTED_PRINTS_STAND_ACCESSORY_TYPE) {
          accessoryConfiguration = {
            id: accessory.id,
            quantity: accessory.quantity,
            standType: accessory.standType,
            mattedPrintsProductType: accessory.mattedPrintsProductType,
            shopCode: accessory.shopCode
          };
        }
      });
    }
    return accessoryConfiguration;
  }
}

export async function getConfigurationOptionalPriceParams(filters, configuration) {
  let params = {};

  if (
    (filters && filters.paperLaminationTypes && filters.paperLaminationTypes.length > 0) ||
    (!filters && configuration)
  ) {
    params.paperLaminationType = configuration.paperLaminationType;
  }

  return params;
}

export async function getConfigurationRequiredPriceParams(configuration, standConfiguration) {
  standConfiguration = standConfiguration
    ? standConfiguration
    : {
        quantity: 1,
        standType: 'CONFIG_MATTED_PRINTS_STAND_NO',
        mattedPrintsProductType: 'MATTED_PRINTS'
      };
  return {
    frameColorMaterialType: configuration.frameColorMaterialType,
    orientationType: configuration.orientationType,
    formatType: configuration.formatType,
    paperType: configuration.paperType,
    quantity: configuration.quantity,
    standType: standConfiguration.standType,
    standQuantity: standConfiguration.quantity
  };
}
