import { BaseModel } from '../core/models';
import { conf } from 'outlinejs';

export class MattedPrintsConfiguration extends BaseModel {
  // url

  getAbsoluteUrl() {
    return this.urlRoot + '/' + this.id;
  }

  get urlRoot() {
    return conf.settings.MATTED_PRINTS_CONFIGURATIONS_URL;
  }

  // properties

  get id() {
    return this.get('id');
  }

  get configurationGuid() {
    return this.get('configurationGuid');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get productType() {
    return this.get('productType');
  }

  set productType(value) {
    this.set('productType', value);
  }

  get productName() {
    return this.get('productName');
  }

  set productName(value) {
    this.set('productName', value);
  }

  get serviceConfigurationTypeCode() {
    return this.get('serviceConfigurationTypeCode');
  }

  set serviceConfigurationTypeCode(value) {
    this.set('serviceConfigurationTypeCode', value);
  }

  get serviceConfigurationTypeName() {
    return this.get('serviceConfigurationTypeName');
  }

  set serviceConfigurationTypeName(value) {
    this.set('serviceConfigurationTypeName', value);
  }

  get frameEditorProjectId() {
    return this.get('frameEditorProjectId');
  }

  set frameEditorProjectId(value) {
    this.set('frameEditorProjectId', value);
  }

  get packagingConfigurationId() {
    return this.get('packagingConfigurationId');
  }

  set packagingConfigurationId(value) {
    this.set('packagingConfigurationId', value);
  }

  get previewImageFileId() {
    return this.get('previewImageFileId');
  }

  set previewImageFileId(value) {
    this.set('previewImageFileId', value);
  }

  get previewStatus() {
    return this.get('previewStatus');
  }

  set previewStatus(value) {
    this.set('previewStatus', value);
  }

  get eventType() {
    return this.get('eventType');
  }

  set eventType(value) {
    this.set('eventType', value);
  }

  get quantity() {
    return this.get('quantity');
  }

  set quantity(value) {
    this.set('quantity', value);
  }

  get paperType() {
    return this.get('paperType');
  }

  set paperType(value) {
    this.set('paperType', value);
  }

  get paperLaminationType() {
    return this.get('paperLaminationType');
  }

  set paperLaminationType(value) {
    this.set('paperLaminationType', value);
  }

  get orientationType() {
    return this.get('orientationType');
  }

  set orientationType(value) {
    this.set('orientationType', value);
  }

  get formatType() {
    return this.get('formatType');
  }

  set formatType(value) {
    this.set('formatType', value);
  }

  get frameColorMaterialType() {
    return this.get('frameColorMaterialType');
  }

  set frameColorMaterialType(value) {
    this.set('frameColorMaterialType', value);
  }

  get previewImageUrl() {
    return this.get('previewImageUrl');
  }

  set previewImageUrl(value) {
    this.set('previewImageUrl', value);
  }

  get colorCorrectionCode() {
    return this.get('colorCorrectionCode');
  }

  set colorCorrectionCode(value) {
    this.set('colorCorrectionCode', value);
  }
}

export class MattedPrintsProduct extends BaseModel {
  static get modelName() {
    return 'product';
  }

  get id() {
    return this.get('code');
  }

  get frontAssetType() {
    return this.get('frontAssetType');
  }

  get frontAssetUrl() {
    return this.get('frontAssetUrl');
  }

  get name() {
    return this.get('name');
  }
}

export class MattedPrintsPrice extends BaseModel {
  static get modelName() {
    return 'price';
  }

  get formattedPrice() {
    return this.get('formattedPrice');
  }

  get currency() {
    return this.get('currency');
  }

  get price() {
    return this.get('price');
  }
}

// /**------------------- stand configuration -------------------**/

export class MattedPrintsAccessoryConfiguration extends BaseModel {
  // url

  getAbsoluteUrl() {
    return this.urlRoot + '/' + this.id;
  }

  get urlRoot() {
    return conf.settings.MATTED_PRINTS_STAND_CONFIGURATIONS_URL;
  }

  // properties

  static get modelName() {
    return 'accessoryConfiguration';
  }

  get id() {
    return this.get('id');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  set shopCode(value) {
    this.set('shopCode', value);
  }

  get productType() {
    return this.get('productType');
  }

  set productType(value) {
    this.set('productType', value);
  }

  get productName() {
    return this.get('productName');
  }

  set productName(value) {
    this.set('productName', value);
  }

  get quantity() {
    return this.get('quantity');
  }

  set quantity(value) {
    this.set('quantity', value);
  }

  get accessoryType() {
    return this.get('accessoryType');
  }

  set accessoryType(value) {
    this.set('accessoryType', value);
  }

  get mattedPrintsConfigurationId() {
    return this.get('mattedPrintsConfigurationId');
  }

  set mattedPrintsConfigurationId(value) {
    this.set('mattedPrintsConfigurationId', value);
  }

  get mattedPrintsProductType() {
    return this.get('mattedPrintsProductType');
  }

  set mattedPrintsProductType(value) {
    this.set('mattedPrintsProductType', value);
  }

  get standType() {
    return this.get('standType');
  }

  set standType(value) {
    this.set('standType', value);
  }

  get previewStatus() {
    return this.get('previewStatus');
  }

  set previewStatus(value) {
    this.set('previewStatus', value);
  }

  get previewImageFileId() {
    return this.get('previewImageFileId');
  }

  set previewImageFileId(value) {
    this.set('previewImageFileId', value);
  }

  get previewImageUrl() {
    return this.get('previewImageUrl');
  }

  set previewImageUrl(value) {
    this.set('previewImageUrl', value);
  }
}

// /**------------------- stand lookups -------------------**/

export class MattedPrintsStandType extends BaseModel {
  static get modelName() {
    return 'standType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

// /**------------------- lookups -------------------**/

export class MattedPrintsEventType extends BaseModel {
  static get modelName() {
    return 'eventType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class MattedPrintsPaperType extends BaseModel {
  static get modelName() {
    return 'paperType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class MattedPrintsLaminationType extends BaseModel {
  static get modelName() {
    return 'paperLaminationType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class MattedPrintsOrientationType extends BaseModel {
  static get modelName() {
    return 'orientationType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class MattedPrintsFormatType extends BaseModel {
  static get modelName() {
    return 'formatType';
  }

  get id() {
    return this.get('code');
  }

  get nameCm() {
    return this.get('nameCm');
  }

  get nameIn() {
    return this.get('nameIn');
  }

  get isDefault() {
    return this.get('isDefault');
  }
}

export class MattedPrintsFrameColorMaterialType extends BaseModel {
  static get modelName() {
    return 'frameColorMaterialType';
  }

  get id() {
    return this.get('code');
  }

  get name() {
    return this.get('name');
  }

  get fileUrl() {
    return this.get('fileUrl');
  }

  get fileType() {
    return this.get('fileType');
  }

  get isDefault() {
    return this.get('isDefault');
  }

  get displayType() {
    return this.get('displayType');
  }
}
