import { LoadableCollection } from '../core/managers';
import { conf } from 'outlinejs';

import {
  MattedPrintsProduct,
  MattedPrintsPrice,
  MattedPrintsEventType,
  MattedPrintsPaperType,
  MattedPrintsLaminationType,
  MattedPrintsOrientationType,
  MattedPrintsFormatType,
  MattedPrintsFrameColorMaterialType,
  MattedPrintsStandType,
  MattedPrintsAccessoryConfiguration
} from './models';

export class MattedPrintsProductCollection extends LoadableCollection {
  get name() {
    return 'mattedPrintsProducts';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PRODUCTS_URL;
  }

  get model() {
    return MattedPrintsProduct;
  }
}

export class MattedPrintsPriceCollection extends LoadableCollection {
  get name() {
    return 'mattedPrintsPrices';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PRICES_URL;
  }

  get model() {
    return MattedPrintsPrice;
  }
}

export class MattedPrintsEventTypesCollection extends LoadableCollection {
  get name() {
    return 'eventTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_EVENT_TYPE_URL;
  }

  get model() {
    return MattedPrintsEventType;
  }
}

export class MattedPrintsPaperTypesCollection extends LoadableCollection {
  get name() {
    return 'paperTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PAPER_URL;
  }

  get model() {
    return MattedPrintsPaperType;
  }
}

export class MattedPrintsLaminationTypesCollection extends LoadableCollection {
  get name() {
    return 'paperLaminationTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_PAGE_LAMINATION_URL;
  }

  get model() {
    return MattedPrintsLaminationType;
  }

  get loadParams() {
    return ['paperType'];
  }
}

export class MattedPrintsOrientationTypesCollection extends LoadableCollection {
  get name() {
    return 'orientationTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_ORIENTATION_URL;
  }

  get model() {
    return MattedPrintsOrientationType;
  }
}

export class MattedPrintsFormatTypesCollection extends LoadableCollection {
  get name() {
    return 'formatTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_FORMAT_URL;
  }

  get model() {
    return MattedPrintsFormatType;
  }

  get loadParams() {
    return ['orientationType'];
  }
}

export class MattedPrintsFrameColorMaterialTypesCollection extends LoadableCollection {
  get name() {
    return 'frameColorMaterialTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_FRAME_COLOR_MATERIAL_URL;
  }

  get model() {
    return MattedPrintsFrameColorMaterialType;
  }
}

export class MattedPrintsStandTypesCollection extends LoadableCollection {
  get name() {
    return 'standTypes';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_STANDS_URL;
  }

  get model() {
    return MattedPrintsStandType;
  }

  get loadParams() {
    return ['accessoryType'];
  }
}

export class MattedPrintsAccessoryConfigurationCollection extends LoadableCollection {
  get name() {
    return 'accessoryConfigurations';
  }

  get url() {
    return conf.settings.MATTED_PRINTS_STAND_CONFIGURATIONS_URL;
  }

  get model() {
    return MattedPrintsAccessoryConfiguration;
  }
}
