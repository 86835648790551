import { BaseSheetsNumberWidget } from '../../core/components/baseSheetsNumberWidget';
import { sprintf } from '../../core/sprintf';

export class QuantityNumberWidget extends BaseSheetsNumberWidget {
  get name() {
    return 'quantity';
  }

  get label() {
    return this.i18n.gettext('numero matted prints');
  }

  getHelperText() {
    return sprintf(
      this.i18n.gettext('Minimo %s, massimo %s matted prints.'),
      this.props.items.min,
      this.props.items.max
    );
  }

  async handleChange(event) {
    let val = event.target.value;
    if (this.shouldComponentShowConfirmModal()) {
      this.showConfirmModal();
    } else {
      await this.setState({ isChosen: true, value: +val });
      this.doChange();
    }
  }

  doChange() {
    let errors = [];
    let val = this.state.value || 0;
    if (val >= this.props.items.min && val <= this.props.items.max) {
      this.setState({ errors: errors });
      this.props.delegate.setConfigurationItem(this.name, val, null, true);
    } else {
      errors = this.validateMinMaxValue(val, this.props);
      this.setState({ errors: errors });
    }
    if (!val) {
      this.collectErrors();
    }
  }
}
